import { getJssdkConfig } from "@/api/wxconfig";
import { Toast } from "vant";
import { getCookie, getQuery, dealData } from "@/utils/index";
import store from "@/store";
import { createQunfa, createkehuqun, createQunfaCircle } from "@/api/qunfa";

const wx = window.wx;
// 获取微信注入参数
async function getConfigParam(uriPath, agentId) {
  try {
    const params = {
      uriPath,
    };
    if (agentId) {
      params.agentId = agentId;
    }
    const { data } = await getJssdkConfig(params);
    return data;
  } catch (e) {
    console.log(e);
  }
}
const jsApiList = [
  "getCurExternalContact",
  "sendChatMessage",
  "getContext",
  "openUserProfile",
  "getCurExternalChat",
  "openExistedChatWithMsg",
  "navigateToAddCustomer",
  "openEnterpriseChat",
  "setClipboardData",
  "onMenuShareWechat",
  "shareToExternalContact",
  "shareToExternalMoments",
  "shareAppMessage",
  "shareWechatMessage",
  "onMenuShareAppMessage",
  "onMenuShareTimeline",
  "scanQRCode",
  "shareToExternalChat",
  'selectExternalContact',
  "getLocation"
];
// wx.config
export function wxConfig(uriPath) {
  return new Promise((resolve, reject) => {
    getConfigParam(uriPath)
      .then((data) => {
        const { appId, timestamp, nonceStr, signature } = data;
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: appId, // 必填，企业微信的corpID
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        wx.ready(async function () {
          resolve();
        });
        wx.error(function (res) {
          Toast({ position: "top", message: "wx.config fail" });
          reject(res);
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}
// 分享
export async function shareMyData(data, retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.onMenuShareWechat({
      title: data.title, // 分享标题
      desc: data.description, // 分享描述
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: data.imgUrl, // 分享图标
      success: function () {
        // 用户确认分享后执行的回调函数

        resolve();
      },
      error: function (res) {
        if (res.errMsg.indexOf('no permission') > 0) {
          console.log('错误', res)
        }
      },
    });
    wx.onMenuShareAppMessage({
      title: data.title, // 分享标题
      desc: data.description, // 分享描述
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: data.imgUrl, // 分享图标
      enableIdTrans: 0,
      success: function () {
        // 用户确认分享后执行的回调函数
        resolve();
      },
      error: function (res) {
        if (res.errMsg.indexOf('no permission') > 0) {
          console.log('错误', res)
        }
      },
      cancel: async () => {
        // 用户取消分享后执行的回调函数
      },
    });
    wx.onMenuShareTimeline({

      title: data.title, // 分享标题
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: data.imgUrl, // 分享图标
      enableIdTrans: 0,
      success: function () {
        // 用户确认分享后执行的回调函数
        resolve();
      },
      error: function (res) {
        if (res.errMsg.indexOf('no permission') > 0) {

          console.log('错误', res)
        }
      },
      cancel: async () => {
        // 用户取消分享后执行的回调函数
        // if (retry !== true) {
        //   resolve(await onMenuShareTimeline(data, true));
        // } else {
        //   reject();
        // }
      },
    });
  });
}

export async function shareToExternalContact(content) {
  console.log('contentrr', content)
  await initAgentConfig();
  var param = {
    text: {
      content: content.description, // 文本内容
    },
    attachments: [],
  };
  switch (content.type) {
    case 2:
      param.attachments.push({
        msgtype: "image", // 消息类型，必填
        image: {
          mediaid: content.mediaid,      // 图片的素材id
          imgUrl: content.content.imageFullPath, //  图片的imgUrl,跟图片mediaid填其中一个即可
        },
      });
      break;
    case 3:
      param.attachments.push({
        msgtype: "link", // 消息类型，必填
        link: {
          title: content.title, // H5消息标题
          imgUrl: content.cover, // H5消息封面图片URL
          url: content.share_link, // H5消息页面url 必填
        },
      });
      break;
    case 4:
      param.attachments.push({
        msgtype: "miniprogram",    // 消息类型，必填
        miniprogram: {
          appid: content.content.appid,    // 小程序的appid
          title: content.content.title,        // 小程序消息的title
          imgUrl: content.content.imageFullPath,    //小程序消息的封面图。必须带http或者https协议头
          page: content.content.page,        //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
        },
      });
      break;
    case 5:
      param.attachments.push({
        msgtype: "video", // 消息类型，必填
        video: {
          mediaid: content.content, // 视频的素材id
        },
      });
      break;
    case 6:
      param.attachments.push({
        msgtype: "file",    // 消息类型，必填，从3.1.12版本开始支持
        file: {
          mediaid: content.mediaid,        // 文件的素材id，必填
        },
      });
      break;
    case 7:
      param.attachments.push({
        msgtype: "link", // 消息类型，必填
        link: {
          title: content.title, // H5消息标题
          url: content.share_link, // H5消息页面url 必填
        },
      });
      break;
  }
  wx.invoke('shareToExternalContact', param, function (res) {
    if (res.err_msg == "shareToExternalContact:ok") {
      // 正常
      console.log('正常')
    } else {
      // 错误处理
      console.log('错误', res)
    }
  }
  );
}

//群发传入多条附件
export async function shareToQunfa(attachmentList, text, employeeId, mediumData, time2) {
  await initAgentConfig();
  var param = {
    text: {
      content: text, // 文本内容
    },
    attachments: [],
  }
  attachmentList.map((content, index) => {
    switch (content.type) {
      case 2:
        param.attachments.push({
          msgtype: "image", // 消息类型，必填
          image: {
            mediaid: content.mediaid,      // 图片的素材id
            imgUrl: content.content.imageFullPath, //  图片的imgUrl,跟图片mediaid填其中一个即可
          },
        });
        break;
      case 3:
        param.attachments.push({
          msgtype: "link", // 消息类型，必填
          link: {
            title: content.title, // H5消息标题
            imgUrl: content.cover, // H5消息封面图片URL
            url: content.share_link, // H5消息页面url 必填
          },
        });
        break;
      case 4:
        param.attachments.push({
          msgtype: "miniprogram",    // 消息类型，必填
          miniprogram: {
            appid: content.content.appid,    // 小程序的appid
            title: content.content.title,        // 小程序消息的title
            imgUrl: content.content.imageFullPath,    //小程序消息的封面图。必须带http或者https协议头
            page: content.content.page,        //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
          },
        });
        break;
      case 5:
        param.attachments.push({
          msgtype: "video", // 消息类型，必填
          video: {
            mediaid: content.content, // 视频的素材id
          },
        });
        break;
      case 6:
        param.attachments.push({
          msgtype: "file",    // 消息类型，必填，从3.1.12版本开始支持
          file: {
            mediaid: content.mediaid,        // 文件的素材id，必填
          },
        });
        break;
      case 7:
        param.attachments.push({
          msgtype: "link", // 消息类型，必填
          link: {
            title: content.title, // H5消息标题
            url: content.share_link, // H5消息页面url 必填
          },
        });
        break;
    }
  })

  wx.invoke('shareToExternalContact', param, function (res) {
    if (res.err_msg == "shareToExternalContact:ok") {
      // 正常
      console.log('正常', res)
      let mediumCon = dealData(text, mediumData)
      var filterParams2 = {}
      console.log('mediumCon', mediumCon)
      createQunfa({
        name: mediumCon[0].content != '' ? mediumCon[0].content : mediumCon[1].msgType,
        employeeIds: employeeId,
        filterParams: JSON.stringify(filterParams2),
        textContent: param.text,
        definiteTime: time2,
        type: 1,
        sendWay: 1, //1代表立即发送 2代表定时发送
        content: JSON.stringify(mediumCon),
        sendType: 2, //发送给我的客户
      }).then((res) => {
        console.log("res", res);
        // this.$message.success("创建成功");
        // this.$router.push({ path: "/qunfa/index" });
      });
    } else {
      // 错误处理
      console.log('错误', res)
    }
  }
  );
}
// 发送多个附件发送到客户群
export async function shareTokehuqun(attachmentList, text, employeeId, mediumData, time2) {
  await initAgentConfig();
  var param = {
    text: {
      content: text, // 文本内容
    },
    attachments: [],
  }
  attachmentList.map((content, index) => {
    switch (content.type) {
      case 2:
        param.attachments.push({
          msgtype: "image", // 消息类型，必填
          image: {
            mediaid: content.mediaid,      // 图片的素材id
            imgUrl: content.content.imageFullPath, //  图片的imgUrl,跟图片mediaid填其中一个即可
          },
        });
        break;
      case 3:
        param.attachments.push({
          msgtype: "link", // 消息类型，必填
          link: {
            title: content.title, // H5消息标题
            imgUrl: content.cover, // H5消息封面图片URL
            url: content.share_link, // H5消息页面url 必填
          },
        });
        break;
      case 4:
        param.attachments.push({
          msgtype: "miniprogram",    // 消息类型，必填
          miniprogram: {
            appid: content.content.appid,    // 小程序的appid
            title: content.content.title,        // 小程序消息的title
            imgUrl: content.content.imageFullPath,    //小程序消息的封面图。必须带http或者https协议头
            page: content.content.page,        //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
          },
        });
        break;
      case 5:
        param.attachments.push({
          msgtype: "video", // 消息类型，必填
          video: {
            mediaid: content.content, // 视频的素材id
          },
        });
        break;
      case 6:
        param.attachments.push({
          msgtype: "file",    // 消息类型，必填，从3.1.12版本开始支持
          file: {
            mediaid: content.mediaid,        // 文件的素材id，必填
          },
        });
        break;
      case 7:
        param.attachments.push({
          msgtype: "link", // 消息类型，必填
          link: {
            title: content.title, // H5消息标题
            url: content.share_link, // H5消息页面url 必填
          },
        });
        break;
    }
  })

  wx.invoke('shareToExternalChat', param, function (res) {
    if (res.err_msg == "shareToExternalChat:ok") {
      // 正常
      console.log('正常')
      let mediumCon = dealData(text, mediumData)
      var filterParams2 = {}
      console.log('mediumCon', mediumCon)
      createkehuqun({
        batchTitle: mediumCon[0].content != '' ? mediumCon[0].content : mediumCon[1].msgType,
        employeeIds: employeeId,
        textContent: param.text,
        definiteTime: time2,
        filterParams: JSON.stringify(filterParams2),
        type: 2,
        sendWay: 1, //1代表立即发送 2代表定时发送
        content: JSON.stringify(mediumCon),
        sendType: 2, //发送给我的客户
      }).then((res) => {
        console.log("res", res);
        // this.$message.success("创建成功");
        this.$router.push({ path: "/qunfa/index" });
      });
    } else {
      // 错误处理
      console.log('错误', res)
    }
  }
  );
}
// 发送多个附件发送到朋友圈
export async function shareToCircle(attachmentList, text2, employeeId, mediumData, time2) {
  await initAgentConfig();
  var param = {
    text: {
      content: text2, // 文本内容
    },
    attachments: [],
  }
  attachmentList.map((content, index) => {
    switch (content.type) {
      case 2:
        param.attachments.push({
          msgtype: "image", // 消息类型，必填
          image: {
            mediaid: content.mediaid,      // 图片的素材id
            imgUrl: content.content.imageFullPath, //  图片的imgUrl,跟图片mediaid填其中一个即可
          },
        });
        break;
      case 3:
        param.attachments.push({
          msgtype: "link", // 消息类型，必填
          link: {
            title: content.title, // H5消息标题
            imgUrl: content.cover, // H5消息封面图片URL
            url: content.share_link, // H5消息页面url 必填
          },
        });
        break;
      case 4:
        param.attachments.push({
          msgtype: "miniprogram",    // 消息类型，必填
          miniprogram: {
            appid: content.content.appid,    // 小程序的appid
            title: content.content.title,        // 小程序消息的title
            imgUrl: content.content.imageFullPath,    //小程序消息的封面图。必须带http或者https协议头
            page: content.content.page,        //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
          },
        });
        break;
      case 5:
        param.attachments.push({
          msgtype: "video", // 消息类型，必填
          video: {
            mediaid: content.content, // 视频的素材id
          },
        });
        break;
      case 6:
        param.attachments.push({
          msgtype: "file",    // 消息类型，必填，从3.1.12版本开始支持
          file: {
            mediaid: content.mediaid,        // 文件的素材id，必填
          },
        });
        break;
      case 7:
        param.attachments.push({
          msgtype: "link", // 消息类型，必填
          link: {
            title: content.title, // H5消息标题
            url: content.share_link, // H5消息页面url 必填
          },
        });
        break;
    }
  })

  wx.invoke('shareToExternalMoments', param, function (res) {
    if (res.err_msg == "shareToExternalMoments:ok") {
      // 正常
      console.log('正常')
      let mediumCon = dealData(text2, mediumData)
      var filterParams2 = {}
      createQunfaCircle({
        name: mediumCon[0].content != '' ? mediumCon[0].content : mediumCon[1].msgType,
        employeeIds: employeeId,
        filterParams: JSON.stringify(filterParams2),
        textContent: param.text,
        definiteTime: time2,
        type: 3,
        sendWay: 1, //1代表立即发送 2代表定时发送
        content: JSON.stringify(mediumCon),
        sendType: 2, //发送给我的客户
      }).then((res) => {
        console.log("res", res);
        // this.$message.success("创建成功");
        this.$router.push({ path: "/qunfa/index" });
      });
    } else {
      // 错误处理
      console.log('错误', res)
    }
  }
  );
}

// wx.agentConfig
export function agentConfig(uriPath, agentId) {
  return new Promise((resolve, reject) => {
    getConfigParam(uriPath, agentId)
      .then((data) => {
        const { corpid, agentid, timestamp, nonceStr, signature } = data;
        if (!wx.agentConfig && Number(localStorage.fails) < 4) {
          if (localStorage.fails) {
            localStorage.fails = Number(localStorage.fails) + 1;
          } else {
            localStorage.fails = 1;
          }
          window.location.reload();
          return false;
        }
        localStorage.fails = 0;
        wx.agentConfig({
          corpid: corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList,
          success: function (res) {
            // Toast({ position: 'top', message: '注册成功' })
            resolve();
          },
          fail: function (res) {
            Toast({ position: "top", message: "wx.agentConfig fail" });
            reject(res);
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export async function initAgentConfig() {
  if (store.getters.initAgentConfig) {
    // return;
  }

  let agentId = getQuery("agentId");

  if (!agentId) {
    agentId = getCookie("workbench_agentId");
  }

  if (!agentId) {
    return;
  }

  // 从企业微信3.0.24及以后版本（可通过企业微信UA判断版本号），无须先调用wx.config，可直接wx.agentConfig.
  // await wxConfig(fullPath)
  const url = window.location.href.split("#")[0];
  await agentConfig(encodeURIComponent(url), agentId);
  store.commit("SET_INIT_AGENT_CONFIG", true);
}
export async function getContext(retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("getContext", {}, async function (res) {
      if (res.err_msg === "getContext:ok") {
        const entry = res.entry; // 返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools
        resolve(entry);
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await getContext(true));
        } else {
          // 错误处理
          reject(res.err_msg);
        }
      }
    });
  });
}
// 获取当前对话客户微信userId
export async function getCurExternalContact(retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("getCurExternalContact", {}, async function (res) {
      if (res.err_msg === "getCurExternalContact:ok") {
        const userId = res.userId; // 返回当前外部联系人userId
        // commit('SET_WX_USER_ID', userId)
        resolve(userId);
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await getCurExternalContact(true));
        } else {
          // 错误处理
          reject(res.err_msg);
        }
      }
    });
  });
}

export async function launchMiniprogram() {
  await initAgentConfig();
  wx.invoke('launchMiniprogram', {
    "appid": "wxbe848a55e8bfd418", // 需跳转的小程序appid
    "path": "pages/index/index", // 所需跳转的小程序内页面路径及参数。非必填
  }, function (res) {
    if (res.err_msg == "launchMiniprogram:ok") {
      // 正常
      console.log('正常')
    } else {
      // 错误处理
      console.log('错误', res)
    }
  }
  );
}
export async function sendChatMessage(type, content, retry) {
  // 1 文本 2 图片 3 图文 5 视频 7文件
  if (type == 6) {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      Toast.fail("Mac端暂不支持发送小程序");
      return;
    }
  }
  let param;
  switch (type) {
    case 1:
      param = {
        msgtype: "text",
        text: {
          content,
        },
      };
      break;
    case 2:
      param = {
        msgtype: "image",
        image: {
          mediaid: content.content,
        },
      };
      break;
    case 3:
      param = {
        msgtype: "news",
        news: {
          link: content.content.imageLink,
          title: content.content.title,
          desc: content.content.title,
          imgUrl: content.content.imageFullPath,
        },
      };
      break;
    case 8:
      param = {
        msgtype: "news",
        news: {
          link: content.content.imageLink,
          title: content.content.title,
          desc: content.content.title,
          imgUrl: content.content.imageFullPath,
        },
      };
      break;
    case 5:
      param = {
        msgtype: "video",
        video: {
          mediaid: content.content,
        },
      };
      break;
    case 6:
      param = {
        msgtype: "miniprogram",
        miniprogram: {
          appid: content.appid,
          title: content.title,
          imgUrl: content.imgUrl,
          page: content.page,
        },
      };
      break;
    case 7:
      param = {
        msgtype: "file",
        file: {
          mediaid: content.content,
        },
      };
      break;
  }
  param.enterChat = true;

  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("sendChatMessage", param, async function (res) {
      if (res.err_msg === "sendChatMessage:ok") {
        // 发送成功
        resolve();
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await sendChatMessage(type, content, true));
        } else {
          Toast({ position: "top", message: res.err_msg });
          reject(res.err_msg);
        }
      }
    });
  });
}
// getCurExternalChat
export async function openUserProfile(type, userid, retry) {
  const params = {
    type,
    userid,
  };
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("openUserProfile", params, async function (res) {
      if (res.err_msg === "openUserProfile:ok") {
        // const userId = res.userId // 返回当前外部联系人userId
        // resolve(userId)
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          await openUserProfile(type, userid, true);
        } else {
          Toast({ position: "top", message: res.err_msg });
          // 错误处理
          reject(res.err_msg);
        }
      }
    });
  });
}
// 获取当前客户群的群ID
export async function getCurExternalChat(retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("getCurExternalChat", {}, async function (res) {
      if (res.err_msg === "getCurExternalChat:ok") {
        const chatId = res.chatId; // 返回当前外部联系人userId
        resolve(chatId);
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await getCurExternalChat(true));
        }
      }
    });
  });
}
// 打开当前群聊
export async function openExistedChatWithMsg(chatId, data, retry) {
  await initAgentConfig();
  const params = {
    chatId,
    msg: {
      msgtype: "link",
      link: {
        title: data.title,
        url: data.share_link,
        // imgUrl: "imgurl1"
      }
    }
  };
  return new Promise((resolve, reject) => {
    wx.invoke("openExistedChatWithMsg", params, async function (res) {
      if (res.err_msg === "openExistedChatWithMsg:ok") {
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await openExistedChatWithMsg(chatId, true));
        } else {
          Toast({ position: "top", message: res.errmsg });
          // 错误处理
          reject(res.errmsg);
        }
      }
    });
  });
}
// 建群
export async function openEnterpriseChat(
  userIds,
  externalUserIds,
  groupName,
  retry
) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.openEnterpriseChat({
      userIds,
      externalUserIds,
      groupName,
      success: function (res) { },
      fail: async function (res) {
        if (res.errMsg.indexOf("function not exist") > -1) {
          alert("版本过低请升级");
        }

        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(
            await openEnterpriseChat(userIds, externalUserIds, groupName, true)
          );
        }
      },
    });
  });
}
// 剪贴板
export async function setClipboardData(data) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.setClipboardData({
      data,
      success: function (res) { },
      fail: async function (res) { },
    });
  });
}
// 进入添加客户界面  只支持移动端
export async function navigateToAddCustomer(retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke("navigateToAddCustomer", {}, function (res) {
      if (res.err_msg === "navigateToAddCustomer:ok") {
      } else {
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(navigateToAddCustomer(true));
        } else {
          Toast({ position: "top", message: res.errmsg });
          // 错误处理
          reject(res.errmsg);
        }
      }
    });
  });
}
// 转发
export async function forward(data, retry) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.onMenuShareAppMessage({
      title: data.title, // 分享标题
      desc: data.description, // 分享描述
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: data.imgUrl, // 分享图标
      enableIdTrans: 0,
      success: function () {
        // 用户确认分享后执行的回调函数
        resolve();
      },
      error: function (res) {
        if (res.errMsg.indexOf('no permission') > 0) {
          console.log('错误', res)
        }
      },
      cancel: async () => {
        // 用户取消分享后执行的回调函数
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await forward(data, true));
        } else {
          reject();
        }
      },
    });
  });
}
//自定义转发到微信
export async function forwardToWechat(data, retry) {
  await initAgentConfig();
  console.log(456)
  return new Promise((resolve, reject) => {
    wx.invoke("shareWechatMessage", {
      title: data.title, // 分享标题
      desc: data.description, // 分享描述
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: data.imgUrl, // 分享图标
      enableIdTrans: 0,
      success: function () {
        // 用户确认分享后执行的回调函数
        resolve();
      },
      cancel: async () => {
        // 用户取消分享后执行的回调函数
        if (retry !== true) {
          store.commit("SET_INIT_AGENT_CONFIG", false);
          resolve(await forwardToWechat(data, true));
        } else {
          reject();
        }
      },
    });
  });
}
//获取“分享到朋友圈”按钮点击状态及自定义分享内容接口
export function onMenuShareTimeline(data, retry) {
  //async await initAgentConfig2();
  console.log(2222)
  return new Promise((resolve, reject) => {
    wx.onMenuShareTimeline({

      title: data.title, // 分享标题
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: data.imgUrl, // 分享图标
      enableIdTrans: 0,
      success: function () {
        // 用户确认分享后执行的回调函数
        resolve();
      },
      error: function (res) {
        if (res.errMsg.indexOf('no permission') > 0) {

          console.log('错误', res)
        }
      },
      cancel: async () => {
        // 用户取消分享后执行的回调函数
        // if (retry !== true) {
        //   resolve(await onMenuShareTimeline(data, true));
        // } else {
        //   reject();
        // }
      },
    });
    store.commit("SET_INIT_AGENT_CONFIG2", false);
  });
}
//发送至客户朋友圈
export async function shareToExternalMoments(text, content) {
  await initAgentConfig();
  console.log('text data', text, content)
  var param = {
    text: {
      content: text, // 文本内容
    },
    attachments: [],
  };
  switch (content.type) {
    case 1:
      param.attachments.push({
        msgtype: "image", // 消息类型，必填
        image: {
          mediaid: content.mediaid,      // 图片的素材id
          imgUrl: content.content.imageFullPath, //  图片的imgUrl,跟图片mediaid填其中一个即可
        },
      });
      break;
    case 2:
      param.attachments.push({
        msgtype: "image", // 消息类型，必填
        image: {
          mediaid: content.mediaid,      // 图片的素材id
          // imgUrl: content.content.imageFullPath, //  图片的imgUrl,跟图片mediaid填其中一个即可
        },
      });
      break;
    case 3:
      param.attachments.push({
        msgtype: "link", // 消息类型，必填
        link: {
          title: content.content.title, // H5消息标题
          imgUrl: content.content.imageFullPath, // H5消息封面图片URL
          url: content.content.imageLink, // H5消息页面url 必填
        },
      });
      break;
    // case 8:
    //   param.attachments.push({
    //     msgtype: "link", // 消息类型，必填
    //     link: {
    //       title: content.content.title, // H5消息标题
    //       imgUrl: content.content.imageFullPath, // H5消息封面图片URL
    //       url: content.content.imageLink, // H5消息页面url 必填
    //     },
    //   });
    //   break;
    case 4:
      param.attachments.push({
        msgtype: "video", // 消息类型，必填
        video: {
          mediaid: content.content, // 视频的素材id
        },
      });
      break;
  }
  return new Promise((resolve, reject) => {
    wx.invoke("shareToExternalMoments", param, async function (res) {
      console.log('3435553')
      if (res.err_msg == "shareToExternalMoments:ok") {
        console.log(1111344)
        resolve();
      }
      else {
        reject(res)
      }
    });
  });
}
//扫码
export async function onScanQRCode(skip) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.scanQRCode({
      desc: 'scanQRCode desc',
      needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码）
      success: function (res) {
        var result = res.resultStr;//当needResult为1时返回处理结果
        skip(result)
      },
      cancel: async () => {

      },
    });
  });
}
//群发
export async function shareToExternalChat(content) {
  await initAgentConfig();
  var param = {
    text: {
      content: content.description, // 文本内容
    },
    attachments: [],
  };
  switch (content.type) {
    case 2:
      param.attachments.push({
        msgtype: "image", // 消息类型，必填
        image: {
          mediaid: content.mediaid,      // 图片的素材id
          imgUrl: content.content.imageFullPath, //  图片的imgUrl,跟图片mediaid填其中一个即可
        },
      });
      break;
    case 3:
      param.attachments.push({
        msgtype: "link", // 消息类型，必填
        link: {
          title: content.title, // H5消息标题
          imgUrl: content.cover, // H5消息封面图片URL
          url: content.share_link, // H5消息页面url 必填
        },
      });
      break;
    case 4:
      param.attachments.push({
        msgtype: "miniprogram",    // 消息类型，必填
        miniprogram: {
          appid: content.content.appid,    // 小程序的appid
          title: content.content.title,        // 小程序消息的title
          imgUrl: content.content.imageFullPath,    //小程序消息的封面图。必须带http或者https协议头
          page: content.content.page,        //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
        },
      });
      break;
    case 5:
      param.attachments.push({
        msgtype: "video", // 消息类型，必填
        video: {
          mediaid: content.content, // 视频的素材id
        },
      });
      break;
    case 6:
      param.attachments.push({
        msgtype: "file",    // 消息类型，必填，从3.1.12版本开始支持
        file: {
          mediaid: content.mediaId,        // 文件的素材id，必填
        },
      });
      break;
    case 7:
      param.attachments.push({
        msgtype: "link", // 消息类型，必填
        link: {
          title: content.title, // H5消息标题
          url: content.share_link, // H5消息页面url 必填
        },
      });
      break;
  }
  wx.invoke('shareToExternalChat', param, function (res) { });
}
//自定义分享
export async function shareAppMessage(data) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.invoke(
      "shareAppMessage", {
      title: data.title, // 分享标题
      desc: data.description, // 分享描述
      link: data.url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
      imgUrl: data.imgUrl, // 分享图标
      enableIdTrans: 1, // 是否开启id转译，不填默认为0
    }, function (res) {
      if (res.err_msg == "shareAppMessage:ok") {
        //正确处理
      } else {
        //错误处理
      }
    }
    );
  });
}
//获取用户位置
export async function getUserLocation(data) {
  await initAgentConfig();
  return new Promise((resolve, reject) => {
    wx.getLocation({
      type: 'gcj02',
      success: (res) => {
        resolve(res);
      },
      fail: (error) => {
        console.error(error);
      },
    });
  });
}
