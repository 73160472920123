import request from "../plugins/axios";

// 获取群发列表
export function qunfaList(params) {
    return request({
        url: '/contactMessageBatchSend/contactList',
        method: 'get',
        params: params
    })
}
// 创建群发
export function createQunfa(params) {
    return request({
        url: '/contactMessageBatchSend/store',
        method: 'post',
        data: params
    })
}
// 群发客户 基本详情 数据统计
export function dataStatistics(params) {
    return request({
        url: '/contactMessageBatchSend/contactShow',
        method: 'get',
        params: params
    })
}
// 群发客户 成员详情
export function membersDetail(params) {
    return request({
        url: '/contactMessageBatchSend/contactEmployeeShow',
        method: 'get',
        params: params
    })
}

//群发客户 客户详情
export function coustomDetail(params) {
    return request({
        url: '/contactMessageBatchSend/contactReceiveIndex',
        method: 'get',
        params: params
    })
}

//群发客户 员工个人群发详情
export function employeeDetail(params) {
    return request({
        url: '/contactMessageBatchSend/employeeDetail',
        method: 'get',
        params: params
    })
}
// 获取群发客户群列表
export function roomList(params) {
    return request({
        url: '/roomMessageBatchSend/roomList',
        method: 'get',
        params: params
    })
}
// 创建客户群群发
export function createkehuqun(params) {
    return request({
        url: '/roomMessageBatchSend/store',
        method: 'post',
        data: params
    })
}
// 群发客户群 基本详情 数据统计
export function roomDataStatistics(params) {
    return request({
        url: '/roomMessageBatchSend/roomShow',
        method: 'get',
        params: params
    })
}
// 群发客户群 客户群接受详情
export function receiveDetail(params) {
    return request({
        url: '/roomMessageBatchSend/roomResultShow',
        method: 'get',
        params: params
    })
}
// 群发客户 提醒员工执行
export function remindEmployee(params) {
    return request({
        url: '/contactMessageBatchSend/remindSend',
        method: 'get',
        params: params
    })
}
// 群发客户群 提醒员工执行
export function remindRoomEmployee(params) {
    return request({
        url: '/roomMessageBatchSend/remind',
        method: 'get',
        params: params
    })
}
// 群发客户群 群主发送详情
export function sendDetail(params) {
    return request({
        url: '/roomMessageBatchSend/roomOwnerShow',
        method: 'get',
        params: params
    })
}

// 群发朋友圈 列表
export function qunfaCircleList(params) {
    return request({
        url: '/workMomentMessageBatchSend/momentList',
        method: 'get',
        params: params
    })
}

// 群发朋友圈 创建
export function createQunfaCircle(params) {
    return request({
        url: '/workMomentMessageBatchSend/momentCreate',
        method: 'post',
        data: params
    })
}
// 群发朋友圈 基本详情 数据统计
export function circleStatistics(params) {
    return request({
        url: '/workMomentMessageBatchSend/momentShow',
        method: 'get',
        params: params
    })
}
// 群发朋友圈提醒
export function remindCircle(params) {
    return request({
        url: '/workMomentMessageBatchSend/remindSend',
        method: 'get',
        params: params
    })
}

// 群发朋友圈 员工统计
export function clientStatic(params) {
    return request({
        url: '/workMomentMessageBatchSend/momentDetail',
        method: 'get',
        params: params
    })
}
//朋友圈互动统计
export function interactStatic(params) {
    return request({
        url: '/workMomentMessageBatchSend/momentComments',
        method: 'get',
        params: params
    })
}
//群发朋友圈互动个人详情
export function circleStatic(params) {
    return request({
        url: '/workMomentMessageBatchSend/momentCommentsDetail',
        method: 'get',
        params: params
    })
}